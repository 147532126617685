<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
@font-face {
  font-family: 'hongmeng';
  src: url('../public/fonts/HarmonyOS Sans.ttf') format('truetype'),
  url('../public/fonts/HarmonyOS Sans.woff') format('woff'),
  url('../public/fonts/HarmonyOS Sans.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}
html{
  background-color: #f3f4f7;
}
#app {
  font-family: hongmeng, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: 14px;
}
</style>
