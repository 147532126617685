export function timeShowDiff(dateString) {
    let dateObj = new Date(dateString);
    let now = new Date();

    let difference = now - dateObj;

    let seconds = Math.floor(difference / 1000);
    let minutes = Math.floor(difference / (1000 * 60));
    let hours = Math.floor(difference / (1000 * 60 * 60));
    let days = Math.floor(difference / (1000 * 60 * 60 * 24));
    let months = Math.floor(difference / (1000 * 60 * 60 * 24 * 30));
    let years = Math.floor(difference / (1000 * 60 * 60 * 24 * 365));

    if (years > 0) {
        return years + "年前";
    } else if (months > 0) {
        return months + "月前";
    } else if (days > 0) {
        return days + "天前";
    } else if (hours > 0) {
        return hours + "小时前";
    } else if (minutes > 0) {
        return minutes + "分钟前";
    } else {
        return seconds + "秒前";
    }

}
