import Vue from 'vue'
import App from './App.vue'
// 引入vant
import Vant from 'vant'
import 'vant/lib/index.css'
import { numberToCurrencyNo } from '@/utils/numberToCurrency'
import { timeShowDiff } from '@/utils/timeDiff'
import VueRouter from 'vue-router'
import router from './router'

Vue.config.productionTip = false
Vue.use(Vant)
// 配置全局过滤器，实现数字千分位格式
Vue.filter('numberToCurrency', numberToCurrencyNo)
// 转化时间格式
Vue.filter('timeDiff', timeShowDiff)
Vue.use(VueRouter)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
