export function numberToCurrencyNo(value) {
    if (typeof (value) == 'undefined') {
        return 0;
    }
    // 将数字转换为字符串
    const amountStr = String(value);

    // 分割整数部分和小数部分
    const parts = amountStr.split('.');

    // 格式化整数部分
    const formattedIntegerPart = parts[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');

    return formattedIntegerPart;

}
